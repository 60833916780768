//import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
//import { FiSettings } from "react-icons/fi";
import { FiUsers } from "react-icons/fi";
import { FiRotateCcw } from "react-icons/fi";
import { FiMessageSquare } from "react-icons/fi";
import { TiTree } from "react-icons/ti";
import Img from "gatsby-image";
//mport { FiPhoneCall } from "react-icons/fi";
import { motion } from "framer-motion";
//import BackgroundImage from "gatsby-background-image-es5";

function HomeFeatures(props) {
	return (
		<div>
			<div className="logo shadow-xl pattern-bg">
				<div className="px-4 py-12 sm:py-16 mx-auto md:grid md:px-6 lg:px-8">
					<div className="text-center">
						<motion.div
							style={{ originY: 1 }}
							initial="hidden"
							animate="visible"
							variants={{
								hidden: {
									scale: 0.8,
									opacity: 0,
								},
								visible: {
									scale: 1,
									opacity: 1,
									transition: {
										delay: 1.2,
									},
								},
							}}
						>
							<Img
								className="main-logo"
								fluid={props.image}
								alt="tree service janesville wisconsin"
							/>
						</motion.div>
						<motion.div
							style={{ originY: 1 }}
							initial="hidden"
							animate="visible"
							variants={{
								hidden: {
									scale: 0.8,
									opacity: 0,
								},
								visible: {
									scale: 1,
									opacity: 1,
									transition: {
										delay: 1.4,
									},
								},
							}}
						>
							<h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-800 sm:text-4xl sm:leading-10">
								{props.heading}
							</h3>
							<p className="mt-4 max-w-2xl text-xl leading-7 text-gray-700 lg:mx-auto font-light">
								{props.copy}
							</p>
						</motion.div>
					</div>
				</div>
			</div>
			{/*<!-- second section -->*/}
			<div className="relative emergency-service">
				<div className="bg-gray-700">
					<div className="px-4 py-12 sm:py-16 mx-auto md:grid md:px-6 lg:px-8">
						<div className="mt-10">
							<ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
								<li>
									<div className="flex">
										<div className="flex-shrink-0">
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-mdgray text-white">
												<FiUsers />
											</div>
										</div>

										<div className="ml-4">
											<h3 className="text-lg leading-6 font-medium text-white">
												{props.featuresTitle1}
											</h3>
											<p className="mt-2 text-base leading-6 text-white font-light">
												{props.featuresTagline1}
											</p>
										</div>
									</div>
								</li>
								<li className="mt-10 md:mt-0">
									<div className="flex">
										<div className="flex-shrink-0">
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-mdgray text-white">
												<TiTree />
											</div>
										</div>
										<div className="ml-4">
											<h4 className="text-lg leading-6 font-medium text-white">
											{props.featuresTitle2}
											</h4>
											<p className="mt-2 text-base leading-6 text-white font-light">
											{props.featuresTagline2}
											</p>
										</div>
									</div>
								</li>
								<li className="mt-10 md:mt-0">
									<div className="flex">
										<div className="flex-shrink-0">
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-mdgray text-white">
												<FiRotateCcw />
											</div>
										</div>
										<div className="ml-4">
											<h4 className="text-lg leading-6 font-medium text-white">
											{props.featuresTitle3}
											</h4>
											<p className="mt-2 text-base leading-6 text-white font-light">
											{props.featuresTagline3}
											</p>
										</div>
									</div>
								</li>
								<li className="mt-10 md:mt-0">
									<div className="flex">
										<div className="flex-shrink-0">
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-mdgray text-white">
												<FiMessageSquare />
											</div>
										</div>
										<div className="ml-4">
											<h4 className="text-lg leading-6 font-medium text-white">
											{props.featuresTitle4}
											</h4>
											<p className="mt-2 text-base leading-6 text-white font-light">
											{props.featuresTagline4}
											</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div className="mt-8 flex justify-center">
							<div className="rounded-md shadow">
								<motion.button
									whileHover={{ scale: 1.04 }}
									whileTap={{ scale: 0.9 }}
								>
									<a
										href={"/about"}
										className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-800 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
									>
										<TiTree /> <span className="call"> Learn More</span>
									</a>
								</motion.button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default HomeFeatures;
