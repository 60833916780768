//import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import { RiQuestionnaireLine } from "react-icons/ri";
import { motion } from "framer-motion";

function FaqList(props) {
	console.log(props);
	return (
		<div className="bg-white">
			<div className="mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-12 lg:px-8">
				<h2
					className="text-3xl leading-9 tracking-tight font-extrabold text-gray-800 sm:text-4xl sm:leading-10"
					style={{ display: "flex", alignItems: "center" }}
				>
					<RiQuestionnaireLine style={{ display: "inline" }} />
					<span style={{ display: "inline", marginLeft: "5px" }}>
						{props.faqHeading}
					</span>
				</h2>
				<div className="mt-4">
				<dl className="md:grid md:grid-cols-2 md:gap-8">
							{props.faqs.edges.map((question, i) => (
								<div key={i} className="mt-12">
									<dt className="text-lg leading-6 font-medium text-gray-900">
										{question.node.frontmatter.title}
									</dt>
									<dd className="mt-2">
										<p
											dangerouslySetInnerHTML={{ __html: question.node.html }}
											className="text-base leading-6 text-gray-600 font-light"
										></p>
									</dd>
								</div>
							))}
						</dl>
				</div>
				<div className="mt-8 flex justify-center">
					<div className="rounded-md shadow">
						<motion.button
							whileHover={{ scale: 1.04 }}
							whileTap={{ scale: 0.9 }}
						>
							<a
								href="/faq"
								className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
							>
								<RiQuestionnaireLine style={{ display: "inline" }} />{" "}
								<span className="call"> View All</span>
							</a>
						</motion.button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FaqList;
