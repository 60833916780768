//import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


function HomeTestimonials(props) {
	console.log(props);
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		swipeToSlide: true,
		autoplay: true,
		autoplaySpeed: 6000,
	};
	// console.log("testimonials " + props);
	return (
		<section className="bg-red">
			<div className="relative overflow-hidden shadow-xl">
				<section className="py-12 px-4 text-center">
					<div className="max-w-2xl mx-auto">
						<h2 className="my-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10">
							What’s So Great About LP Tree Service?
						</h2>
						<Slider {...settings}>
						{props.testimonials.map((testimonial, i) => (
							<div key={i} className="px-4 md:p-8">
								<div className="relative flex items-center p-8">
									<div className="absolute inset-0 bg-white rounded" />
									<div className="z-10 w-full md:m-4 text-gray-800">
										<p className="text-lg mb-5 font-light">
											{testimonial.node.rawMarkdownBody}
										</p>
										<p className="font-semibold">{testimonial.node.frontmatter.name}</p>
										<p>{testimonial.node.frontmatter.title}</p>
									</div>
								</div>
							</div>
							))}
						</Slider>
					</div>
				</section>
			</div>
		</section>
	);
}

export default HomeTestimonials;
