//import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import Img from "gatsby-image";
import { MdContentPaste } from "react-icons/md";
import { motion } from "framer-motion";

function BlogTeaser(props) {
	return (
		<div
			className="mx-auto px-4 sm:px-6 lg:px-8 flex flex-wrap items-center justify-between p-4 pt-16 lg:pb-12"
		>
			<div className="">
				<div className="bg-white h-1/3 sm:h-2/3" />
			</div>
			<div className="relative max-w-7xl mx-auto">
				<div>
					<h2
						style={{ display: "flex", alignItems: "center" }}
						className="text-3xl leading-9 tracking-tight font-extrabold text-gray-800 sm:text-4xl sm:leading-10"
					>
						<MdContentPaste style={{ display: "inline" }} />
						<span style={{ display: "inline", marginLeft: "5px" }}>
							{" "}
							{props.blogHeading}
						</span>
					</h2>
					{/*
					<p className="mt-3 max-w-2xl text-xl leading-7 text-gray-500 sm:mt-4">
						Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa
						libero labore natus atque, ducimus sed.
					</p>
					*/}
				</div>
				<div className="mt-4 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
					{props.posts.edges.map((post, index) => {
						return (
							<div
								key={index}
								className="flex flex-col rounded-lg shadow-lg overflow-hidden"
							>
								<div className="flex-shrink-0">
									<Img
										className="h-48 w-full object-cover"
										fluid={post.node.frontmatter.image.childImageSharp.fluid}
										alt=""
									/>
									{/*
									<img
										className="h-48 w-full object-cover"
										src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
										alt=""
									/>
										*/}
								</div>
								<div className="flex-1 bg-white p-6 flex flex-col justify-between">
									<div className="flex-1">
										<a
											href={"/posts/" + post.node.frontmatter.slug}
											className="block"
										>
											<h3 className="mt-2 text-xl leading-7 font-semibold text-gray-800">
												{post.node.frontmatter.title}
											</h3>
											<p className="mt-3 text-base leading-6 text-gray-600 font-light">
												{post.node.excerpt}
											</p>
										</a>
									</div>
									<div className="mt-6 flex items-center">
										{/*
										<div className="flex-shrink-0">
											<a href="/#">
												<img
													className="h-10 w-10 rounded-full"
													src="/assets/LP-TREE-circle.jpg"
													alt=""
												/>
											</a>
										</div>
										*/}
										<div className="">
											<p className="text-sm leading-5 font-medium text-gray-900">
												LP Tree Service
											</p>
											<p className="text-sm leading-5 text-gray-500">
												Janesville, WI
											</p>
											<div className="flex text-sm leading-5 text-gray-500">
												<time dateTime="2020-03-16">
													{post.node.frontmatter.date}
												</time>
												<span className="mx-1">·</span>
												<span>{post.node.fields.readingTime.text}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>

				<div className="mt-8 flex justify-center">
					<div className="rounded-md shadow">
						<motion.button
							whileHover={{ scale: 1.04 }}
							whileTap={{ scale: 0.9 }}
						>
							<a
								href="/blog"
								className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
							>
								<MdContentPaste style={{ display: "inline" }} />{" "}
								<span className="call"> View All</span>
							</a>
						</motion.button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BlogTeaser;
