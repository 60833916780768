//import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { motion } from "framer-motion";
import BackgroundImage from "gatsby-background-image-es5";

function HomeCTA(props) {
	return (
		<BackgroundImage
			fluid={props.bgimage}
			className="relative overflow-hidden shadow-xl"
		>
			<div
				className="mx-auto relative"
				style={{
					backgroundSize: "cover",
					backgroundPosition: "top center",
					background:
						"linear-gradient(to right, rgba(0,0,0,0.7), rgba(0,0,0,0.2))",
				}}
			>
				<div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-20 lg:px-8">
					<h2 className="text-3xl leading-9 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10" dangerouslySetInnerHTML={{__html: props.ctaHeading }}>
						
					</h2>
					<div className="mt-8 flex justify-center">
						<div className="rounded-md shadow">
							<motion.button
								whileHover={{ scale: 1.04 }}
								whileTap={{ scale: 0.9 }}
							>
								<a
									href={"tel:" + props.phone}
									className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-800 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
								>
									<FiPhoneCall /> <span className="call"> Call Us!</span>
								</a>
							</motion.button>
						</div>
					</div>
				</div>
			</div>
		</BackgroundImage>
	);
}

export default HomeCTA;
