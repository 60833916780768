import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import HomeHero from "../components/homeHero";
import HomeFeatures from "../components/HomeFeatures";
import HomeTestimonials from "../components/HomeTestimonials";
// import HomePricing from "../components/HomePricing";
import HomeCTA from "../components/HomeCTA";
import FaqList from "../components/faqList";
import BlogTeaser from "../components/BlogTeaser";
import SEO from "../components/seo";

function IndexPage({ data }) {
	let homePage = data.homeContent.edges[0].node.frontmatter;
	let testimonials = data.homeTestimonial.edges;
	//console.log(data);
	return (
		<Layout>
			<SEO
				keywords={[`Treecare`, `Tree Services`, `Janesville`, `Wisconsin`, `Arborist`]}
				title="Home"
			/>

			<HomeHero
				headline={homePage.homeBannerHeadline}
				copy={homePage.homeBannerHeadText}
				phone={homePage.homeBannerPhone}
				image={homePage.homeBannerImage.childImageSharp.fluid}
				style={{
					backgroundColor: "transparent",
					fill: "#fff",
					backgroundSize: "500px",
					backgroundRepeat: "repeat",
					backgroundImage:
						"url('data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 500 500' style='enable-background:new 0 0 500 500;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0{fill:rgba(255,255,255,1);} %3C/style%3E%3Cg%3E%3Crect x='-26' class='st0' width='553' height='50'/%3E%3C/g%3E%3Cg%3E%3Crect x='-26' y='100' class='st0' width='553' height='50'/%3E%3C/g%3E%3Cg%3E%3Crect x='-26' y='200' class='st0' width='553' height='50'/%3E%3C/g%3E%3Cg%3E%3Crect x='-26' y='300' class='st0' width='553' height='50'/%3E%3C/g%3E%3Cg%3E%3Crect x='-26' y='400' class='st0' width='553' height='50'/%3E%3C/g%3E%3C/svg%3E')",
				}}
			/>
			<HomeFeatures
				image={homePage.homeLogoImage.childImageSharp.fluid}
				bgimage={homePage.homeBannerImage.childImageSharp.fluid}
				heading={homePage.secondBannerHeadline}
				copy={homePage.secondBannerHeadText}
				featuresTitle1={homePage.featuresTitle1}
				featuresTagline1={homePage.featuresTagline1}
				featuresTitle2={homePage.featuresTitle2}
				featuresTagline2={homePage.featuresTagline2}
				featuresTitle3={homePage.featuresTitle3}
				featuresTagline3={homePage.featuresTagline3}
				featuresTitle4={homePage.featuresTitle4}
				featuresTagline4={homePage.featuresTagline4}
			/>

			<HomeTestimonials testimonialHeading={homePage.testimonialHeading} testimonials={testimonials} />


			<HomeCTA
				phone={homePage.homeBannerPhone}
				bgimage={homePage.homeCTAImage.childImageSharp.fluid}
				ctaHeading={homePage.ctaHeading}
			/>

			{/*
			<HomePricing />
			*/}
			<FaqList faqs={data.homeFAQ} faqHeading={homePage.faqHeading} />

			<BlogTeaser posts={data.blogPosts} blogHeading={homePage.blogHeading} />
		</Layout>
	);
}

export default IndexPage;

export const indexQuery = graphql`
	query {
		blogPosts: allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "post" } } }
			sort: { order: DESC, fields: frontmatter___date }
			limit: 3
		) {
			edges {
				node {
					id
					fields {
						readingTime {
							text
						}
					}
					frontmatter {
						date(fromNow: true)
						title
						slug
						image {
							childImageSharp {
								fluid(maxWidth: 200, maxHeight: 200) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					excerpt(truncate: true)
				}
			}
		}
		homeContent: allMarkdownRemark(
			filter: { frontmatter: { pageName: { eq: "home" } } }
		) {
			edges {
				node {
					frontmatter {
						homeBannerHeadline
						homeBannerHeadText
						homeBannerPhone
						homeBannerImage {
							id
							childImageSharp {
								fluid(quality: 70, fit: COVER) {
									...GatsbyImageSharpFluid
								}
							}
							absolutePath
						}
						homeCTAImage {
							id
							childImageSharp {
								fluid(quality: 70, fit: COVER) {
									...GatsbyImageSharpFluid
								}
							}
							absolutePath
						}
						homeTestimonialImage {
							id
							childImageSharp {
								fluid(quality: 70, fit: COVER) {
									...GatsbyImageSharpFluid
								}
							}
							absolutePath
						}
						homeLogoImage {
							id
							childImageSharp {
								fluid(quality: 70, fit: COVER) {
									...GatsbyImageSharpFluid
								}
							}
							absolutePath
						}
						secondBannerHeadline
						secondBannerHeadText
						featuresTitle1
						featuresTagline1
						featuresTitle2
						featuresTagline2
						featuresTitle3
						featuresTagline3
						featuresTitle4
						featuresTagline4
						testimonialHeading
						ctaHeading
						blogHeading
						faqHeading
					}
					rawMarkdownBody
					id
					html
				}
			}
		}
		homeFAQ: allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "faq" }, title: {} } }
			sort: { order: DESC, fields: frontmatter___date }
			limit: 4
		) {
			edges {
				node {
					frontmatter {
						title
						date
					}
					rawMarkdownBody
					html
				}
			}
		}
		homeTestimonial: allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "testimonial" }, title: {} } }
		) {
			edges {
				node {
					frontmatter {
						name
						title
					}
					rawMarkdownBody
					html
				}
			}
		}
	}
`;
